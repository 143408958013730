:root {
  --achim-blue: #27aae1;
  --achim-green: #294a9f;
  --achim-pink: #bb358f;
  --achim-orange: #bb358f;
  --donors-blue: #294a9f;
  --donors-light: #27aae1;
  --donors-3: #983890;
  --donors-4: #cd1487;
  --donors-pink: #bb358f;
  --donors-gradient: linear-gradient(90deg, #294a9f 0%, #27aae1 100%);
  --donors-gradient2: linear-gradient(47deg, #294a9f 0%, #27aae1 100%);
  --donors-gradient3: linear-gradient(90deg, #983890 0%, #cd1487 100%);

}

.bg-donors-blue {
  background-color: var(--donors-blue);
}

.bg-donors-light {
  background-color: var(--donors-light);
}

.bg-donors-pink {
  background-color: var(--donors-pink);
}

.text-donors-blue {
  color: var(--donors-blue);
}

.text-donors-light {
  color: var(--donors-light);
}

.text-donors-pink {
  color: var(--donors-pink);
}

.bg-donors-g1 {
  background-color: var(--donors-blue);
  background: var(--donors-gradient);
}

.bg-donors-g2 {
  background-color: var(--donors-blue);
  background: var(--donors-gradient2);
}

.bg-donors-g3 {
  background-color: var(--donors-pink);
  background: var(--donors-gradient3);
}


.bg-donors-g1 {
  background-color: var(--donors-blue);
  background: var(--donors-gradient);
}

.btn-donors-blue {
  background-color: var(--donors-blue);
  background: var(--donors-gradient);
  color: #fff !important;
  border: none !important;
}

.btn-donors-blue:hover {
  background-color: var(--donors-light);
  background: var(--donors-gradient);
}

.btn-donors-pink {
  background-color: var(--donors-pink);
  background: var(--donors-gradient3);
  color: #fff !important;
  border: none !important;
}


.btn-donors-pink:hover {
  background-color: var(--donors-3);
  background: var(--donors-gradient);
}

.table-sort::after {
  opacity: 0.3;
  padding-top: 5px;
}


.load-text {
  animation: slide 5s linear infinite alternate;
}

@keyframes slide {
  0% {
    color: var(--achim-blue);
  }

  25% {
    color: var(--achim-green);
  }

  50% {
    color: var(--achim-pink);
  }

  75% {
    color: var(--achim-orange);
  }

  100% {
    color: var(--achim-blue);
  }
}

option {
  font-size: 1.2rem;
}

.pending {
  z-index: 500;
  background-color: rgba(255, 255, 255, 0.77);
}

.pending-load {
  background-color: rgba(255, 255, 255);
}

.bell-icon {
  position: relative;
}

.bell-icon:after {
  content: attr(data-bell-counter);
  font-family: 'Courier New', Courier, monospace;
  top: -10px;
  right: 10px;
  position: absolute;
  font-size: 0.85rem;
  height: 20px;
  width: 20px;
  background: red;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.nav-logo {
  position: absolute;
  align-items: center;
  left: calc(50% - 34px);
}

.achim-shadow {
  box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.23) !important;
  -webkit-box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.23);
}

.achim-shadow:hover {
  box-shadow: 1px 1px 18px 2px rgba(0, 0, 0, 0.23) !important;
  -webkit-box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.22);
}

.study-slider:hover {
  transform: scale(1.03);
  transition: all 1s;
}

.card-bg-image:hover .opacity-4:hover {
  opacity: 0.8;
}

.alert-dismissible .close {
  right: 10px !important;
  left: 0 !important;
  top: 50% !important;
}

body {
  text-align: right !important;
}

.hover-row-class:hover {
  background-color: #8ed4f0;
}

.rtl-print {
  direction: rtl !important;
}

/* .hover-row-class:hover .edit-donor-cell{
  background-color: rgb(122, 150, 224);
  color: aliceblue;
}

.hover-row-class:hover .edit-donor-cell:hover{
  background-color: #0b689e;
  color: aliceblue;
}

.hover-row-class:hover .edit-id-cell{
  background-color: #a0ffa5;
}

.hover-row-class:hover .edit-id-cell:hover{
  background-color: #2c6277;
  color: aliceblue;
} */

.fixed-button {
  position: absolute !important;
  top: 100px;
  left: 50px;
}

.fixed-button-2 {
  position: absolute !important;
  top: 20px;
  left: 20px;
}

.card-register label {
  margin-top: 15px;
}

.card-register .card-title {
  color: #B33C12;
  text-align: center;
}

.card-register .btn {
  margin-top: 30px;
}

.card-register .forgot {
  text-align: center;
}


.page-header {
  min-height: 100vh;
  max-height: 999px;
  padding: 0;
  background-color: var(--donors-blue);
  background: var(--donors-gradient2);
  background-image: url("../../../assets/img/theme/bg.jpg");
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.select-campaign {
  border: none;
}

.table-flush tr:hover {
  background-color: rgb(215, 235, 255);
}


/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #b8b6b9 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 16px;
  border: 3px solid #ffffff;
}

.thead-lighter>td,
.thead-lighter th {
  background-color: #d6dfe2;
}

.thead-sticky {
  position: sticky;
  top: -1px;
}